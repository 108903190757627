import { GET_FEEDBACK_FORM, RESPONSE_SUBMITTED } from "../constants/response";

const initialState = {
  feedbackForm: null,
  store: null,
  feedbacker: null,
  forceThankyouScreen: false,
  ffAction: null,
  submittedResponse: null,
};

export default function response(state = initialState, action) {
  console.log("action.type", action)
  switch (action.type) {
    case GET_FEEDBACK_FORM:
      return {
        ...state,
        feedbackForm: action.data,
        store: action.store,
        feedbacker: action.feedbacker,
        forceThankyouScreen: action.forceThankyouScreen,
        ffAction: action.ffAction,
      };

    case RESPONSE_SUBMITTED:
      return {
        ...state,
        submittedResponse: action.data,
      }

    default:
      return state;
  }
}
