import axios from "axios";

import {
  FETCH_FEEDBACK_LIST,
  FETCH_FEEDBACK_FORMS_LIST,
  FETCH_CUSTOM_TABLE_FILTERS,
  FETCH_CUSTOM_TABLE_FILTER,
  FETCH_CUSTOM_TABLE_FIELDS,
  FETCH_FEEDBACK_DETAILS,
  FETCH_FEEDBACK_DETAILS_ANSWERS,
  FETCH_FEEDBACK_DETAILS_TASKS,
} from "../constants/feedbacks";

const FEEDBACK_BASE_URL = `${process.env.REACT_APP_API_URL}/feedbacks/apiv2`;

export const fetchFeedbacks = (data) => async (dispatch, getState) => {
  const token = getState().auth.token;
  if (token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    try {
      let response = await axios.get(`${FEEDBACK_BASE_URL}/feedbacks-list/`, {
        headers,
        params: data,
      });
      if (response.status === 200) {
        dispatch({ type: FETCH_FEEDBACK_LIST, data: response.data });
      }
    } catch (error) {
      throw error;
    }
  }
};

export const fetchFeedbackFormList = () => async (dispatch, getState) => {
  const token = getState().auth.token;
  if (token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    try {
      let response = await axios.get(
        `${FEEDBACK_BASE_URL}/feedbacks-list/forms/`,
        { headers }
      );
      if (response.status === 200) {
        dispatch({ type: FETCH_FEEDBACK_FORMS_LIST, data: response.data });
      }
    } catch (error) {
      throw error;
    }
  }
};

export const fetchCustomTableFilterList = () => async (dispatch, getState) => {
  const token = getState().auth.token;
  if (token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    try {
      let response = await axios.get(
        `${FEEDBACK_BASE_URL}/custom-table-filter/`,
        { headers }
      );
      if (response.status === 200) {
        dispatch({
          type: FETCH_CUSTOM_TABLE_FILTERS,
          data: response.data.data,
        });
      }
    } catch (error) {
      throw error;
    }
  }
};

export const createCustomTableFilter = (data) => async (dispatch, getState) => {
  const token = getState().auth.token;
  if (token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    try {
      let response = await axios.post(
        `${FEEDBACK_BASE_URL}/custom-table-filter/`,
        data,
        { headers }
      );
      if (response.status === 200) {
        dispatch({
          type: FETCH_CUSTOM_TABLE_FILTERS,
          data: response.data.data,
        });
      }
    } catch (error) {
      throw error;
    }
  }
};

export const deleteCustomTableFilter = (id) => async (dispatch, getState) => {
  const token = getState().auth.token;
  if (token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    try {
      let response = await axios.delete(
        `${FEEDBACK_BASE_URL}/custom-table-filter/?custom_table_id=${id}`,
        { headers }
      );
      if (response.status === 200) {
        dispatch({
          type: FETCH_CUSTOM_TABLE_FILTERS,
          data: response.data.data,
        });
      }
    } catch (error) {
      throw error;
    }
  }
};

export const fetchCustomTableFilter = (id) => async (dispatch, getState) => {
  const token = getState().auth.token;
  if (token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    try {
      let response = await axios.get(
        `${FEEDBACK_BASE_URL}/custom-table-filter/${
          id ? `?custom_table_id=${id}` : ""
        }`,
        { headers }
      );
      if (response.status === 200) {
        dispatch({ type: FETCH_CUSTOM_TABLE_FILTER, data: response.data.data });
      }
    } catch (error) {
      throw error;
    }
  }
};

export const fetchCustomTableFields = () => async (dispatch, getState) => {
  const token = getState().auth.token;
  if (token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    try {
      let response = await axios.get(
        `${FEEDBACK_BASE_URL}/custom-table-fields/`,
        { headers }
      );
      if (response.status === 200) {
        dispatch({ type: FETCH_CUSTOM_TABLE_FIELDS, data: response.data.data });
      }
    } catch (error) {
      throw error;
    }
  }
};

export const fetchFeedbackDetails = (data) => async (dispatch, getState) => {
  const token = getState().auth.token;
  if (token) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    try {
      let response = await axios.get(
        `${FEEDBACK_BASE_URL}/feedbacks/details/?feedback_id=${data}`,
        { headers }
      );
      if (response.status === 200) {
        dispatch({ type: FETCH_FEEDBACK_DETAILS, data: response.data.data });
      }
    } catch (error) {
      throw error;
    }
  }
};

export const fetchFeedbackDetailsAnswers =
  (data) => async (dispatch, getState) => {
    const token = getState().auth.token;
    if (token) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      try {
        let response = await axios.get(
          `${FEEDBACK_BASE_URL}/feedbacks/details/answers/?feedback_id=${data}`,
          { headers }
        );
        if (response.status === 200) {
          dispatch({
            type: FETCH_FEEDBACK_DETAILS_ANSWERS,
            data: response.data,
          });
        }
      } catch (error) {
        throw error;
      }
    }
  };

export const fetchFeedbackDetailsTasks =
  (data) => async (dispatch, getState) => {
    const token = getState().auth.token;
    if (token) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      try {
        let response = await axios.get(
          `${FEEDBACK_BASE_URL}/feedbacks/details/tasks/?feedback_id=${data}`,
          { headers }
        );
        if (response.status === 200) {
          dispatch({ type: FETCH_FEEDBACK_DETAILS_TASKS, data: response.data });
        }
      } catch (error) {
        throw error;
      }
    }
  };

export const fetchDetailedFeedbackReport =
  (params) => async (dispatch, getState) => {
    const token = getState().auth.token;
    if (token) {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      try {
        let response = await axios.get(
          `${FEEDBACK_BASE_URL}/generate-detailed-report/`,
          { headers, params }
        );
        if (response.status === 200) {
          return response.data;
        }
      } catch (error) {
        throw error;
      }
    }
  };
