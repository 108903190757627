import { NavLink as Link } from "react-router-dom";
import { Icon } from "@ant-design/compatible";
import { Menu } from "antd";
import { SideMenu } from "../../../constants/data";
import styles from "./sidebar.module.scss";

function SidebarMenu({
    showSidebar,
    hideSidebar,
    theme,
}) {
  return (
    <Menu
      onMouseEnter={() => showSidebar()}
      onMouseLeave={() => hideSidebar()}
      theme={theme}
      mode="inline"
      style={{
        marginTop: "4rem",
        height: "inherit",
        background: "rgba(215,215,215,0.1)",
      }}
    >
      {SideMenu.map((menu) => {
        return (
          <Menu.Item
            key={menu.key}
            className={
              window.location.pathname === menu.link
                ? "ant-menu-item-selected"
                : ""
            }
          >
            {menu.external ? (
              <a href={menu.link}>
                <Icon type={menu.icon} />
                <span className={styles.menu_text}>{menu.name}</span>
              </a>
            ) : (
              <Link to={menu.link}>
                <Icon type={menu.icon} />
                <span className={styles.menu_text}>{menu.name}</span>
              </Link>
            )}
          </Menu.Item>
        );
      })}
    </Menu>
  );
}

export default SidebarMenu;
