import React, { Component, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
import { auth } from "./actions";

import Preloader from "./components/generics/preloader";

import FormSetting from "./components/Setting/FormSetting";
import TextSetting from "./components/Setting/TextSetting";
import ImageSetting from "./components/Setting/ImageSetting";
import DeviceSetting from "./components/Setting/DeviceSetting";
import DashboardSetting from "./components/Setting/DashboardSetting";
import ForgotPassword from "./components/Login/ForgotPassword";
import ChangePassword from "./components/Login/ChangePassword";

const GoogleOAuthCallback = React.lazy(() =>
  import("./components/OAuth/Google/index")
);
const InstagramOAuthCallback = React.lazy(() =>
  import("./components/OAuth/Instagram/index")
);
const AddGoogleLocation = React.lazy(() =>
  import("./components/OAuth/Google/AddGoogleLocation")
);
const TwitterOAuthCallback = React.lazy(() =>
  import("./components/OAuth/Twitter/index")
);
const NotFound = React.lazy(() => import("./components/generics/notfound"));
const Index = React.lazy(() => import("./components"));
const ProxyLogin = React.lazy(() => import("./components/Login"));
const Login = React.lazy(() => import("./components/Login/Login"));
const Register = React.lazy(() => import("./components/Login/Register"));
const Dashboard = React.lazy(() => import("./components/Dashboard"));
const Employee = React.lazy(() => import("./components/Employee"));
const FeedbackForms = React.lazy(() => import("./components/FeedbackForms/index"));
const FeedbackFormsDetails = React.lazy(() =>
  import("./components/FeedbackForms/FeedbackFormDetail/")
);
const Response = React.lazy(() => import("./components/Response"));
const Templates = React.lazy(() => import("./components/Templates"));
const TemplateDetail = React.lazy(() =>
  import("./components/Templates/TemplateDetail")
);
const Customers = React.lazy(() => import("./components/Customers"));
const CustomerDetails = React.lazy(() =>
  import("./components/Customers/CustomerDetails")
);
const UploadCustomers = React.lazy(() =>
  import("./components/Customers/Upload")
);
const EmailCampaign = React.lazy(() =>
  import("./components/Customers/Campaign/Email")
);
const CreateEmailCampaign = React.lazy(() =>
  import("./components/Customers/Campaign/Email/CreateEmailCampaign")
);
const EmailSettings = React.lazy(() =>
  import("./components/Customers/Campaign/Email/Settings")
);
const BalanceHistory = React.lazy(() => import("./components/BalanceHistory"));
const UnsubscribeView = React.lazy(() =>
  import("./components/Customers/UnsubscribeView")
);
const CampaignReport = React.lazy(() =>
  import("./components/Reports/Campaign")
);
const DetailedReport = React.lazy(() =>
  import("./components/Reports/Campaign/DetailedReport")
);
const Feedbacks = React.lazy(() => import("./components/Feedbacks"));
const FeedbackDetails = React.lazy(() =>
  import("./components/Feedbacks/FeedbackDetails")
);
const Reviews = React.lazy(() => import("./components/Reviews"));
const Members = React.lazy(() => import("./components/Members"));
const AddMember = React.lazy(() =>
  import("./components/Members/AddMembersForm/index")
);
const ReedemCoupon = React.lazy(() =>
  import("./components/Members/ReedemCoupon")
);
//import member details
const MemberDetails = React.lazy(() =>
  import("./components/Members/MemberDetails")
);

const Preview = React.lazy(() => import("./components/Preview"));

const EditMemberDetails = React.lazy(() =>
  import("./components/Members/MemberDetails/EditDetails/index")
);

// Report Routes

const ReportPage = React.lazy(() => import("./components/ReportPage"));
const GenerateReport = React.lazy(() =>
  import("./components/ReportPage/GenerateReport")
);

// Settings Routes

const SettingPage = React.lazy(() => import("./components/Setting"));

// Subscription Routes

const SubscriptionPage = React.lazy(() => import("./components/Subscription"));

// const ReviewReport = React.lazy(() => import('./components/Reviews/ReviewsList/index'))

const AccountsManage = React.lazy(() =>
  import("./components/Reviews/AccountsManage")
);

class Routes extends Component {
  componentDidMount() {
    if (!this.props.auth.isAuthenticated) {
      this.props.loadUser();
    }
  }

  PrivateRoute = ({ component: ChildComponent, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (this.props.auth.isLoading) {
            return <em>Loading...</em>;
          } else if (!this.props.auth.isAuthenticated) {
            return <Redirect to="/login/" />;
          } else {
            return <ChildComponent {...props} />;
          }
        }}
      />
    );
  };

  render() {
    let PrivateRoute = this.PrivateRoute;

    return (
      <Router>
        <Suspense fallback={<Preloader />}>
          <Switch>
            <Route path="/" component={Index} exact />
            <Route path="/redirect/login/" component={ProxyLogin} exact />
            <Route path="/login/" component={Login} exact />
            <Route path="/forgot-password/" component={ForgotPassword} exact />
            <Route
              path="/password-reset/:token"
              component={ChangePassword}
              exact
            />
            <Route path="/register/" component={Register} exact />

            <Route
              path="/google/callback/"
              component={GoogleOAuthCallback}
              exact
            />
            <Route
              path="/google/locations/"
              component={AddGoogleLocation}
              exact
            />

            <Route
              path="/instagram/callback"
              component={InstagramOAuthCallback}
              exact
            />

            <Route
              path="/twitter/callback"
              component={TwitterOAuthCallback}
              exact
            />

            <Route path="/unsub/:secret/" component={UnsubscribeView} exact />
            <Route path="/response/:slug/" component={Response} exact />
            <Route path="/response/:slug/:fid" component={Response} exact />

            <Route
              path="/feedback-form-templates/"
              component={Templates}
              exact
            />
            <Route
              path="/feedback-form-templates/c/:slug-feedback-form-templates/"
              component={Templates}
              exact
            />
            <Route
              path="/feedback-form-templates/:slug/"
              component={TemplateDetail}
              exact
            />
            <Route
              path="/feedback-form-templates/:slug/preview/:id"
              component={Preview}
              exact
            />

            <PrivateRoute
              path="/feedback-forms/"
              component={FeedbackForms}
              exact
            />
            <PrivateRoute
              path="/feedback-forms/templates/"
              component={FeedbackForms}
              exact
            />
            <PrivateRoute
              path="/feedback-forms/create/"
              component={FeedbackFormsDetails}
              exact
            />
            <PrivateRoute
              path="/feedback-forms/:formID/"
              component={FeedbackFormsDetails}
              exact
            />

            <PrivateRoute path="/dashboard/" component={Dashboard} exact />
            <PrivateRoute path="/employee/" component={Employee} exact />
            <PrivateRoute path="/report-page/" component={ReportPage} exact />
            <PrivateRoute
              path="/generate-report/:slug/"
              component={GenerateReport}
              exact
            />

            {/* Settings */}
            <PrivateRoute path="/settings" component={SettingPage} exact />
            <PrivateRoute
              path="/setting/form-setting/"
              component={FormSetting}
              exact
            />
            <PrivateRoute
              path="/setting/text-setting/"
              component={TextSetting}
              exact
            />
            <PrivateRoute
              path="/setting/image-setting/"
              component={ImageSetting}
              exact
            />
            <PrivateRoute
              path="/setting/device-setting/"
              component={DeviceSetting}
              exact
            />
            <PrivateRoute
              path="/setting/dashboard-setting/"
              component={DashboardSetting}
              exact
            />

            {/* Subscriptions */}
            <PrivateRoute
              path="/subscriptions"
              component={SubscriptionPage}
              exact
            />

            <PrivateRoute path="/customers/" component={Customers} exact />
            <PrivateRoute
              path="/customers/d/:id/"
              component={CustomerDetails}
              exact
            />
            <PrivateRoute
              path="/customers/upload/"
              component={UploadCustomers}
              exact
            />
            <PrivateRoute
              path="/customers/campaigns/email/"
              component={EmailCampaign}
              exact
            />
            <PrivateRoute
              path="/customers/campaigns/email/:id/"
              component={CreateEmailCampaign}
              exact
            />
            <PrivateRoute
              path="/customers/campaigns/email-settings/"
              component={EmailSettings}
              exact
            />

            <PrivateRoute
              path="/balance-history/"
              component={BalanceHistory}
              exact
            />

            <PrivateRoute
              path="/report/campaign/"
              component={CampaignReport}
              exact
            />
            <PrivateRoute
              path="/report/campaign/:id/"
              component={DetailedReport}
              exact
            />

            <PrivateRoute path="/feedbacks/" component={Feedbacks} exact />
            <PrivateRoute
              path="/feedbacks/:id/"
              component={FeedbackDetails}
              exact
            />

            <PrivateRoute path="/reviews/" component={Reviews} exact />
            {/* <PrivateRoute path='/reviews/r/:id/' component={ReviewReport} exact /> */}
            <PrivateRoute
              path="/reviews/manage/"
              component={AccountsManage}
              exact
            />

            {/* members */}
            <PrivateRoute path="/members/" component={Members} exact />
            <PrivateRoute
              path="/members/add-member"
              component={AddMember}
              exact
            />
            <PrivateRoute
              path="/members/redeem-coupon"
              component={ReedemCoupon}
              exact
            />

            <PrivateRoute
              path="/members/d/:id/"
              component={MemberDetails}
              exact
            />
            <PrivateRoute
              path="/members/edit-details/:id/"
              component={EditMemberDetails}
              exact
            />
            <Route component={NotFound} exact />
          </Switch>
        </Suspense>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUser: () => dispatch(auth.loadUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
