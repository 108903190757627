import axios from 'axios';  // for request 

// Local Imports

import {
  GET_EMPLOYEE_LIST
} from '../constants/employee';

let headers = { 'Content-Type': 'application/json' } // headers 

export const fetchEmployee = (params) => async (dispatch, getState) => {
  let token = getState().auth.token;
  if(token){
    headers['Authorization'] = `Token ${token}`;
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/feedbacktaker/apiv2/feedback-taker/`,
        { headers, params }
      )
      
      if(response.status === 200){
        dispatch({ type: GET_EMPLOYEE_LIST, data: response.data })
      }

    } catch(err) {
      throw err
    }
  }
}

export const fetchPaginatedEmployees = (data) => async (dispatch, getState) => {
  let token = getState().auth.token
  let headers = { 'Content-Type': 'application/json' }
  if (token) {
      headers['Authorization'] = `Token ${token}`
      try {
          let response = await axios.get(
              data.url,
              { headers },
          )
          if (response.status === 200) {
              dispatch({ type: GET_EMPLOYEE_LIST, data: response.data })
          }
      }
      catch (error) {
          throw (error)
      }
  }
}

export const addNewEmployee = (data) => async(dispatch, getState) => {
  let token = getState().auth.token;
  if(token){
    headers['Authorization'] = `Token ${token}`;
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API_URL}/feedbacktaker/apiv2/feedback-taker/`,
        data,
        { headers }
      )
      
      if(response.status === 200){
        dispatch(fetchEmployee())
      }

    } catch(err) {
      throw err
    }
  }
}


export const updateEmployee = (data, id) => async(dispatch, getState) => {
  let token = getState().auth.token;
  if(token){
    headers['Authorization'] = `Token ${token}`;
    try {
      let response = await axios.put(
        `${process.env.REACT_APP_API_URL}/feedbacktaker/apiv2/feedback-taker/${id}`,
        data,
        { headers }
      )
      
      if(response.status === 200){
        dispatch(fetchEmployee())
      }

    } catch(err) {
      throw err
    }
  }
}
