import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import { withRouter, Link } from "react-router-dom";

import Avatars from "@dicebear/avatars";
import sprites from "@dicebear/avatars-initials-sprites";

import { auth } from "../../../actions";
import logo from "../../../assets/img/ff_black_logo.png";
import styles from "./topbar.module.scss";
import {
  AppBar,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  IconButton,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  ListSubheader,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";
import { HomeOutlined, ExpandLess, ExpandMore } from "@material-ui/icons";
import {
  TopbarSoftwareLinks,
  TopbarIndustryMenu,
  NavBarMenu,
} from "../../../constants/data";
import SideBar from "../sidebar";
import SidebarMenu from "../sidebar/SidebarMenu";

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileMenu: null,
      sideNavbar: false,
      industryExtra: false,
      softwareExtra: false,
    };
  }

  handleLogout = () => {
    this.props.logout();
  };

  renderExtraMenu = (item, index) => {
    const { softwareExtra, industryExtra } = this.state;

    const handleSoftwareExtraMenu = () => {
      this.setState({ softwareExtra: !softwareExtra });
    };

    const handleIndustryExtraMenu = () => {
      this.setState({ industryExtra: !industryExtra });
    };

    if (item.key === "softwares") {
      return (
        <>
          <ListItem button key={index} onClick={handleSoftwareExtraMenu}>
            {/* <ListItemIcon>
                            <HomeOutlined />
                        </ListItemIcon> */}
            <ListItemText>{item.name}</ListItemText>
            {softwareExtra ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={softwareExtra} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {TopbarSoftwareLinks.map((menu, index) => (
                <a href={menu.url} key={index}>
                  <ListItem button>
                    <ListItemText secondary={menu.name} />
                  </ListItem>
                </a>
              ))}
            </List>
          </Collapse>
        </>
      );
    } else if (item.key === "industies") {
      return (
        <>
          <ListItem button key={index} onClick={handleIndustryExtraMenu}>
            {/* <ListItemIcon>
                            <HomeOutlined />
                        </ListItemIcon> */}
            <ListItemText>{item.name}</ListItemText>
            {industryExtra ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={industryExtra} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {TopbarIndustryMenu.map((menu, index) => (
                <a href={menu.url} key={index}>
                  <ListItem button>
                    <ListItemText secondary={menu.name} />
                  </ListItem>
                </a>
              ))}
            </List>
          </Collapse>
        </>
      );
    }
  };

  render() {
    const { headerNotFixed, user, isAuthenticated, match } = this.props;
    let showExtraMenu = false;

    if (
      match.path === "/feedback-form-templates/" ||
      match.path === "/feedback-form-templates/:slug/" ||
      match.path === "/"
    ) {
      showExtraMenu = true;
    }

    let options = {
      width: 40,
      height: 40,
      fontSize: 40,
      radius: 100,
    };
    let avatars = new Avatars(sprites, options);
    let svg = avatars.create("FF");
    if (isAuthenticated) {
      svg = avatars.create(
        user ? (user.username ? user.username : "Fe") : "FF"
      );
    }

    const handleProfileMenuOpen = (event) => {
      this.setState({ profileMenu: event.currentTarget });
    };
    const handleProfileClose = () => {
      this.setState({ profileMenu: null });
    };
    const handleSoftwareMenuOpen = (event) => {
      this.setState({ softwareMenu: event.currentTarget });
    };
    const handleSoftwareMenuClose = () => {
      this.setState({ softwareMenu: null });
    };

    const handleIndustryMenuOpen = (event) => {
      this.setState({ industryMenu: event.currentTarget });
    };
    const handleIndustryMenuClose = () => {
      this.setState({ industryMenu: null });
    };

    const getHomeRedirectUrl = () => {
      if (isAuthenticated) {
        return "https://app.fellafeeds.com/dashboard";
      } else return "https://fellafeeds.com";
    };

    const {
      profileMenu,
      softwareMenu,
      industryMenu,
      sideNavbar,
      softwareExtra,
      industryExtra,
    } = this.state;

    const toggleSideNavbar = (value) => (event) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }

      this.setState({ sideNavbar: value });
    };

    return (
      <Layout.Header
        style={{
          position: headerNotFixed ? "unset" : "fixed",
          zIndex: 2,
          width: "100%",
          height: "4rem",
          display: "flex",
          alignItems: "center",
          padding: "0",
        }}
      >
        {window.innerWidth < 720 ? (
          <>
            <AppBar position="static" style={{ background: "#fff" }}>
              <Toolbar className="space-between">
                <div>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleSideNavbar(true)}
                  >
                    <MenuIcon className={styles.darkIcon} />
                  </IconButton>
                  <a href={getHomeRedirectUrl()}>
                    <img src={logo} alt="FellaFeeds" height="50px" />
                  </a>
                </div>
                <div className="flex-center">
                  {isAuthenticated ? (
                    <>
                      <div
                        className={styles.profileMenu}
                        onClick={handleProfileMenuOpen}
                      >
                        <div
                          className={styles.dpContainer}
                          dangerouslySetInnerHTML={{ __html: svg }}
                        />
                      </div>
                      <Menu
                        anchorEl={profileMenu}
                        keepMounted
                        open={Boolean(profileMenu)}
                        onClose={handleProfileClose}
                      >
                        <MenuItem key="feedback-forms">
                          <Link to="/feedback-forms/">Feedback Forms</Link>
                        </MenuItem>
                        {/* <MenuItem key="subscriptions">
                                                            <Link to='/subscriptions/'>
                                                                Subscriptions
                                                            </Link>
                                                        </MenuItem> */}
                        <MenuItem key="settings">
                          <Link to="/settings/">Settings</Link>
                        </MenuItem>
                        <MenuItem
                          onClick={() => this.handleLogout()}
                          key="setting:1"
                        >
                          Logout
                        </MenuItem>
                      </Menu>
                    </>
                  ) : (
                    <Link to="/login/">
                      <Button>Login</Button>
                    </Link>
                  )}
                </div>
              </Toolbar>
            </AppBar>
            <SwipeableDrawer
              anchor="left"
              open={sideNavbar}
              onClose={toggleSideNavbar(false)}
            >
              {isAuthenticated ? (
                <>
                  <SidebarMenu theme={'light'} />
                </>
              ) : (
                <div className={styles.sideNavbar}>
                  <List
                    component="nav"
                    subheader={
                      <ListSubheader component="div">FellaFeeds</ListSubheader>
                    }
                  >
                    {NavBarMenu.map((item, index) =>
                      item.extra ? (
                        this.renderExtraMenu(item, index)
                      ) : item.internal ? (
                        <Link to={item.url}>
                          <ListItem button key={index}>
                            <ListItemText>{item.name}</ListItemText>
                          </ListItem>
                        </Link>
                      ) : (
                        <a href={item.url}>
                          <ListItem button key={index}>
                            <ListItemText>{item.name}</ListItemText>
                          </ListItem>
                        </a>
                      )
                    )}
                  </List>
                </div>
              )}
            </SwipeableDrawer>
          </>
        ) : (
          <AppBar position="static" style={{ background: "#fff" }}>
            <Toolbar className="space-between">
              <a href={getHomeRedirectUrl()}>
                <img src={logo} alt="FellaFeeds" height="50px" />
              </a>
              <div>
                {showExtraMenu ? (
                  <div>
                    <span
                      className={`pointer ${styles.menuItem}`}
                      onClick={handleSoftwareMenuOpen}
                      onMouseClick={handleSoftwareMenuOpen}
                    >
                      Softwares &#9660;
                    </span>
                    <Menu
                      anchorEl={softwareMenu}
                      keepMounted
                      open={Boolean(softwareMenu)}
                      onClose={handleSoftwareMenuClose}
                    >
                      {TopbarSoftwareLinks.map((item, index) => (
                        <MenuItem>
                          <a href={item.url}>{item.name}</a>
                        </MenuItem>
                      ))}
                    </Menu>

                    <Link to="/feedback-form-templates/">
                      <span className={styles.menuItem}>Form Templates</span>
                    </Link>
                    <span
                      className={`pointer ${styles.menuItem}`}
                      onClick={handleIndustryMenuOpen}
                      onMouseClick={handleIndustryMenuOpen}
                    >
                      Industries &#9660;
                    </span>
                    <Menu
                      anchorEl={industryMenu}
                      keepMounted
                      open={Boolean(industryMenu)}
                      onClose={handleIndustryMenuClose}
                    >
                      {TopbarIndustryMenu.map((item, index) => (
                        <MenuItem key={index}>
                          <a href={item.ur}>{item.name}</a>
                        </MenuItem>
                      ))}
                    </Menu>
                    <span className={styles.menuItem}>
                      <a href="https://fellafeeds.com/blogs/">Blogs</a>
                    </span>
                    <span className={styles.menuItem}>
                      <a href="https://fellafeeds.com/userfeed">
                        Redeem Points
                      </a>
                    </span>
                    <span className={styles.menuItem}>
                      <a href="https://fellafeeds.com/request-a-demo">
                        Request a Demo
                      </a>
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="flex-center">
                {isAuthenticated ? (
                  <>
                    <div
                      className={styles.profileMenu}
                      onClick={handleProfileMenuOpen}
                    >
                      <div
                        className={styles.dpContainer}
                        dangerouslySetInnerHTML={{ __html: svg }}
                      />
                    </div>
                    <Menu
                      anchorEl={profileMenu}
                      keepMounted
                      open={Boolean(profileMenu)}
                      onClose={handleProfileClose}
                    >
                      <MenuItem key="feedback-forms">
                        <Link to="/feedback-forms/">Feedback Forms</Link>
                      </MenuItem>
                      {/* <MenuItem key="subscriptions">
                                                            <Link to='/subscriptions/'>
                                                                Subscriptions
                                                            </Link>
                                                        </MenuItem> */}
                      <MenuItem key="settings">
                        <Link to="/settings/">Settings</Link>
                      </MenuItem>
                      <MenuItem
                        onClick={() => this.handleLogout()}
                        key="setting:1"
                      >
                        Logout
                      </MenuItem>
                    </Menu>
                  </>
                ) : (
                  <Link to="/login/">
                    <Button>Login</Button>
                  </Link>
                )}
              </div>
            </Toolbar>
          </AppBar>
        )}
        {/* <Menu theme='light' mode='horizontal' style={{
                    width: '100%',
                    padding: '0 1rem',
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <Menu.Item>
                        <div className='logo'>
                            <a href='https://fellafeeds.com'>
                                <img src={logo} alt='FellaFeeds' height='50px' />
                            </a>
                        </div>
                    </Menu.Item>

                    {
                        showExtraMenu
                            ?
                            <Menu.Item>
                                <Link to="/feedback-form-templates/">
                                    Templates
                                </Link>
                            </Menu.Item>
                            : ''
                    }
                    {
                        isAuthenticated
                            ?
                            <SubMenu
                                title={<div className={styles.dpContainer} dangerouslySetInnerHTML={{ __html: svg }} />}
                                className={styles.dp}
                            >
                                <Menu.Item onClick={() => this.handleLogout()} key="setting:1">Logout</Menu.Item>
                            </SubMenu>
                            :
                            <Menu.Item>
                                <Link to="/login/">
                                    Login
                                </Link>
                            </Menu.Item>
                    }
                </Menu> */}
      </Layout.Header>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  loadUser: (data) => dispatch(auth.loadUser(data)),
  logout: () => dispatch(auth.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TopBar));
