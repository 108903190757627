import React, { useState } from "react";
import { Menu, Layout } from "antd";
import styles from "./sidebar.module.scss";
import SidebarMenu from "./SidebarMenu";

const SideBar = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const showSidebar = () => {
    setCollapsed(false);
  };
  const hideSidebar = () => {
    setCollapsed(true);
  };

  return (
    <Layout.Sider
      // collapsible
      collapsed={collapsed}
      className={styles.sidebar}
      style={{
        overflowY: "auto",
        overflowX: "hidden",
        height: "100vh",
        position: "sticky",
        left: 0,
        top: 0,
      }}
    >
      {/* <Menu
                onMouseEnter={() => showSidebar()}
                onMouseLeave={() => hideSidebar()}
                theme='dark'
                mode='inline'
                style={{
                    marginTop: '4rem',
                    height: 'inherit',
                    background: 'rgba(215,215,215,0.1)'
                }}> */}
      <SidebarMenu
        theme={"dark"}
        showSidebar={showSidebar}
        hideSidebar={hideSidebar}
      />
      {/* </Menu> */}
    </Layout.Sider>
  );
};

export default SideBar;
